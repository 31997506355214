function About() {
  return (
    <div>
    <div id="about" className="page-header">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <h1>About me</h1>
              </div>
          </div>
      </div>
  </div>
    <div className="home-page lol about">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                    <div className="welcome-content content">
                        <header className="entry-header">
                            <h3 className="entry-title">UNDERSTANDING YOU AND YOUR NEEDS BETTER</h3>
                        </header>


                        <div className="entry-content mt-5">
                            <p> Who understand customer’s need and try to fulfill those requirements in an appropriate span of time at a suitable price.  A provider of high-quality of services, maintains relationships of satisfaction, and having extensive knowledge of the real estate market will benefit you. My Maximum Home Value Audit is a much higher level of service than what you may have experienced before.</p>
                            <p className="list">My listings: <a target="_blank" href="https://www.realtor.ca/agent/2104959/nadeem-syed-5200-yonge-st-200-toronto-ontario-m2n5p6">nadeem.syed@zolo.ca</a></p>
                        </div>
                    </div>

                </div>


                <div className="col-12 col-lg-6 mt-4 order-1 order-lg-2">
                    <img src="pic2.jpg" alt="welcome"/>
                </div>

            </div>

        </div>

    </div>
</div>

  );
}

export default About;
