import {useState} from "react";


function Mortgage() {
      const [princple, setPrincple] = useState(0);
      const [rate, setRate] = useState(0);
      const [time, setTime] = useState(0);
      const [mort, setMort] = useState(0);
      const [final, setFinal] = useState(0);
      const [down, setDown] = useState(0);


    const findMort = (e) => {
          e.preventDefault()
          var p= princple-down;
          var i= rate / 100 / 12;
          var n= time * 12;
          console.log(p);
          console.log(i);
          console.log(n);
          setFinal(p);
          setMort((p*i*((1+i)**n))/((1+i)**n-1));
        //  setMort( p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1) );
        }

    return (
      <div id="mortgage">
      <div id="content" className="page-header">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Mortgage</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="mort-page">
            <div className="container">
                <div className="row">
                <div className="col-12 col-lg-7">
                <form className="mort-form" onSubmit={findMort}>
                <div>
                  <label>Property Value</label>
                  <label className="mort-unlabel">Down payment</label>
                </div>
                  <input
                  type="number"
                  step="0.01"
                  required="required"
                  onChange={e => setPrincple( e.target.value )}
                  placeholder="Property Value"
                  name="princple"/>
                  <label className="mort-label">Down payment</label>
                  <input
                  type="number"
                  required="required"
                  step="0.01"
                  onChange={e => setDown( e.target.value )}
                  placeholder="Amount already payed"
                  name="down"/>
                  <label>Mortgage Amount</label>
                  <input
                  type="number"
                  value={princple-down}
                  readOnly="readonly"
                  placeholder="Mortgage Amount"
                  name="final"/>
                  <div>
                  <label>Rate of Interest</label>
                  <label className="mort-unlabel">Mortgage Amortization Period</label></div>
                  <input
                  type="number"
                  required="required"
                  step="0.01"
                  onChange={e => setRate( e.target.value )}
                  placeholder="Rate"
                  name="rate"/>
                  <label className="mort-label">Mortgage Amortization Period</label>
                  <input
                  type="number"
                  required="required"
                  step="0.01"
                  onChange={e => setTime( e.target.value )}
                  placeholder="Years"
                  name="time"/>
                  <h5>Your monthly Mortgage payment</h5>
                  <input
                  type="number"
                  value={mort}
                  readOnly="readonly"
                  step="0.01"
                  placeholder="Mortgage"
                  name="value"/>
                  <p>May vary</p>
                  <input type="submit" value="Find" />
                  </form>

                </div>

                    <div className="col-12 col-lg-5">
                        <div className="entry-content">
                            <h3>A MORTGAGE, OFTEN KNOWN AS MORTGAGE LOAN</h3>
                            <p>It is a contract between you and a mortgage lender that allows you to buy or refinance a property without having to pay the entire amount upfront.</p>
                            <p>In simple words, mortgage loans are used to finance the purchase of a home or to borrow money against the value of an existing home.</p>
                            <h2>   Find your mortgage here.</h2>
                        </div>
                    </div>



                </div>
            </div>
        </div>

        </div>
    );
  }
export default Mortgage;
