import About from './BodCon/About.js';
import Content from './BodCon/Content.js';
import Contact from './BodCon/Contact.js';
import Mortgage from './BodCon/Mortgage.js';

function Body() {
  return (
    <div>
      <About/>
      <Content/>
      <Mortgage/>
      <Contact/>
    </div>
  );
}

export default Body;
