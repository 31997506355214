import Header from './Header.js';
import Body from './Body.js';
import Footer from './Footer.js';

function App() {
  return (
    <div>
      <Header/>
      <Body/>
      <Footer/>
    </div>
  );
}

export default App;
