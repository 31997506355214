import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse
} from 'mdb-react-ui-kit';
import {AiOutlineMenu, AiOutlineMail, AiOutlinePhone, AiOutlineInstagram, AiFillTwitterCircle, AiOutlineFacebook, AiOutlineLinkedin} from "react-icons/ai";


export default function Header() {
  const [showNavExternal, setShowNavExternal] = useState(false);

  return (
    <div>
    <section id="topbar" className="d-flex align-items-center">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">

          <a href="mailto:info@nadshome.com">
          <AiOutlineMail/>
          info@nadshome.com</a>
          <a href="tel:+14168480774"><AiOutlinePhone className="phone-icon"/> 416-848-0774</a>
        </div>
        <div className="social-links d-none d-md-block">
          <a href="https://twitter.com/Nadhomes" target="_blank" className="twitter"><AiFillTwitterCircle/></a>
          <a href="https://www.facebook.com/profile.php?id=100078015210469" target="_blank" className="facebook"><AiOutlineFacebook/></a>
          <a href="https://www.instagram.com/nad.homes/" target="_blank" className="instagram"><AiOutlineInstagram/></a>
          <a href="https://www.linkedin.com/in/nadeem-syed-10a840230/" target="_blank" className="linkedin"><AiOutlineLinkedin/></a>
        </div>
     </div>
  </section>
  <div className="nav-bar">
  <img className="d-block" src="logo1.png" alt="logo"/>
    <div className="container">
        <div className="row">
            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div className="site-branding d-flex align-items-center">
                  <div><h1 className="logo">nadshome real estate</h1></div>
                    </div>

              {
                //branding
              }

                <nav className="site-navigation d-flex justify-content-end align-items-center">
                    <ul className="d-flex flex-column flex-lg-row justify-content-lg-end align-content-center nav-links">
                        <li><a href="#about">About me</a></li>
                        <li><a href="#content">Content</a></li>
                        <li><a href="#mortgage">Mortgage</a></li>
                        <li><a href="#contact">Contact</a></li>
                      </ul>
                      </nav>


                </div>

          {
          //col
        }
          </div>
      {//row
      }
      </div>
   {//container
   }
   </div>
   <div className="nav-links">
     <MDBCollapse show={showNavExternal}>
       <div className='bg-dark p-4'>
         <ul className="nav-link">
        <li> <div className="social">
           <a href="https://twitter.com/Nadhomes" className="twitter"><AiFillTwitterCircle/></a>
           <a href="https://www.facebook.com/profile.php?id=100078015210469" className="facebook"><AiOutlineFacebook/></a>
           <a href="https://www.instagram.com/nad.homes/" target="_blank" className="instagram"><AiOutlineInstagram/></a>
           <a href="https://www.linkedin.com/in/nadeem-syed-10a840230/" className="linkedin"><AiOutlineLinkedin/></a>
         </div></li>
             <li><a href="#about">About me</a></li>
             <li><a href="#content">Content</a></li>
             <li><a href="#mortgage">Mortgage</a></li>
             <li><a href="#contact">Contact</a></li>
           </ul>
       </div>
     </MDBCollapse>
     <MDBNavbar dark bgColor='dark'>
       <MDBContainer fluid>
         <MDBNavbarToggler
         className ="okay"
           type='button'
           data-target='#navbarToggleExternalContent'
           aria-controls='navbarToggleExternalContent'
           aria-expanded='false'
           aria-label='Toggle navigation'
           onClick={() => setShowNavExternal(!showNavExternal)}
         >
         <AiOutlineMenu />
         </MDBNavbarToggler>
       </MDBContainer>
     </MDBNavbar>
     </div>

</div>

  );
}
