import {useState,useRef} from "react";
import {AiOutlineMail, AiOutlinePhone, AiOutlineInstagram, AiFillTwitterCircle, AiOutlineFacebook, AiOutlineLinkedin} from "react-icons/ai";
import {BsHouseDoor} from "react-icons/bs";
import emailjs from '@emailjs/browser';
import { initializeApp } from 'firebase/app';
import { getDatabase, set, ref } from "firebase/database";
// Import the functions you need from the SDKs you need
// TODO: Replace the following with your app's Firebase project configuration




function Contact() {

  /*firebase initiallization*/
  const app = initializeApp({
      apiKey: "AIzaSyDtUkx9zxT6c5QFcEzcX2bjF_rv5bo2iB8",
      authDomain: "nads-53e83.firebaseapp.com",
      databaseURL: "https://nads-53e83-default-rtdb.firebaseio.com",
      projectId: "nads-53e83",
      storageBucket: "nads-53e83.appspot.com",
      messagingSenderId: "387891315386",
      appId: "1:387891315386:web:b5d182c8b6310cd3dc3883",
      measurementId: "G-WN5EMG14H2"});
  const db = getDatabase(app);
  const updateUserData = (user) => {
    user.preventDefault();
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    let dateTime = cDate + ' ' + cTime;
    console.log(user);
      return set(ref(db,"lol/" + dateTime),user)
      .then(()=>{alert("done")});
  };


/*Email.js initiallization*/
  emailjs.init("user_dPaSuscD3RWYuXpYoGX5Z");
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    updateUserData({
      name:form.current.from_name.value,
      email:form.current.email.value,
      number:form.current.number.value
    });
    console.log("oon");
    emailjs.sendForm('service_p62ypav', 'template_mta1jwl', form.current, 'user_dPaSuscD3RWYuXpYoGX5Z')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
};

  return (
    <div  id="contact">
    <div className="page-header">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <h1>Contact</h1>
              </div>
          </div>
      </div>
  </div>
    <div className="contact-page">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-5">
                    <div className="entry-content">

                        <h3>You can reach out to me for help in finding your dream home and for an unforgettable experience.</h3>
                        <div className='row'>
                        <img className="left-panel" src="logo.jpg" alt="logo" width="65px" height="95px"/>
                        <div className='right-panel'>
                        <h3 > Nadeem Syed</h3>
                        <p>ZoLo Realty, Brokerage</p>
                        <ul className="contact-social d-flex flex-wrap align-items-center">
                            <li><a href="https://twitter.com/Nadhomes" target="_blank" className="twitter"><AiFillTwitterCircle/></a></li>
                            <li><a href="https://www.facebook.com/profile.php?id=100078015210469" target="_blank" className="facebook"><AiOutlineFacebook/></a></li>
                            <li><a href="#" className="instagram"><AiOutlineInstagram/></a></li>
                            <li><a href="https://www.linkedin.com/in/nadeem-syed-10a840230/" target="_blank" className="linkedin"><AiOutlineLinkedin/></a></li>

                        </ul>

                        </div></div>
                        <ul className="contact-info p-0">
                            <li><a href="tel:416-848-0774"><AiOutlinePhone className="phone-icon"/> 416-848-0774</a></li>
                            <li>
                              <a href="mailto:info@nadshome.com">
                              <AiOutlineMail/> info@nadshome.com</a></li>

                            <li>
                              <a href="mailto:nadeem.syed@nadshome.com">
                              <AiOutlineMail/> nadeem.syed@nadshome.com</a></li>


                              </ul>

                    </div>
                </div>

                <div className="col-12 col-lg-7">

                    <form className="contact-form" ref={form} onSubmit={sendEmail}>
                      <label>Name</label>
                      <input type="text" required="required" placeholder="Name" name="from_name"/>
                      <label>Email</label>
                      <input type="email" required="required" placeholder="Email" name="email"/>
                      <label>Phone Number</label>
                      <input type="tel" required="required" placeholder="Ph. number" name="number"/>
                      <label>Message</label>
                      <textarea required="required" placeholder="Message" name="message"/>
                      <input type="submit" value="Send" />
                      </form>

                </div>


            </div>
        </div>
    </div>
    </div>
  );
}

export default Contact;
