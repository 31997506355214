function Content() {
  return (
    <div>
    <div id="content" className="page-header">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <h1>Content</h1>
              </div>
          </div>
      </div>
  </div>
    <div className="home-page contentt">
        <div className="container">
            <div className="row">
            <div className="col-12 col-lg-6 mt-4 order-1 order-lg-1">
            <video controls autoPlay muted loop>
              <source src="video1.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>

            </div>


            <div className="col-12 col-lg-6 order-2 order-lg-2">
                    <div className="welcome-content content">
                        <header className="entry-header">
                            <h3 className="entry-title">BEST VALUE FOR MONEY AND LOCATION OFFERED</h3>
                        </header>


                        <div className="entry-content mt-5">
                        <p>We provide a seamless consumer experience that includes everything from search and discovery to transactions, house loans, rentals, property management, and after-sales service.</p>
                        <h6>What I guarantee:</h6>
                        <ul>
                      <li>Integrity: I will be completely transparent and honest in answering any real estate questions you may have.</li>

                      <li>Access: I can get information on any property you're interested in at any moment.</li>

                        <li>Accountability: I owe it to my clients to work harder for them.</li>
                        </ul>
                        </div>


                    </div>

                </div>




            </div>

        </div>

    </div>
</div>
  );
}

export default Content;
